/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - Unikátní muzeum meteoritů"} ogTitle={"Unikátní muzeum meteoritů"} ogImage={"https://cdn.swbpg.com/o/37955/18dcb6de03194183a2f68b0399060439.jpg"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1apdg62 --style3 js-anim  --anim3 --anim-s3 --full" anim={"3"} name={"uvod"} animS={"3"} border={""} parallax={false} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0245c9c270d14821b470b312cc4556c1_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 --full flex--stretch" style={{"maxWidth":1200}} anim={null} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim3 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"3"} animS={"3"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"hq7z1rbw6yq"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":245}} alt={""} src={"https://cdn.swbpg.com/t/37955/ef405aa8ad5140daac890169713f75c0_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/ef405aa8ad5140daac890169713f75c0_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/ef405aa8ad5140daac890169713f75c0_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/ef405aa8ad5140daac890169713f75c0_s=860x_.png 860w, https://cdn.swbpg.com/t/37955/ef405aa8ad5140daac890169713f75c0_s=1400x_.png 1400w"}>
              </Image>

              <Title className="title-box fs--24" content={"<span style=\"color: var(--color-custom-1--95);\"><span style=\"font-weight: bold;\">Jsme unikátní Muzeum meteoritů<br></span><br>&nbsp;<span style=\"font-weight: bold;\">Tvůj kousek vesmíru...</span></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"reference-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"font-weight: bold;\">DĚKUJEME ZA PODPORU MĚSTU</span>"}>
              </Text>

              <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/219a82a5bce14b40ae7d72e1fa58dac8_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/219a82a5bce14b40ae7d72e1fa58dac8_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/219a82a5bce14b40ae7d72e1fa58dac8_s=860x_.png 860w, https://cdn.swbpg.com/t/37955/219a82a5bce14b40ae7d72e1fa58dac8_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/37955/219a82a5bce14b40ae7d72e1fa58dac8_s=860x_.png"} lightbox={false} use={"url"} href={"https://www.frydekmistek.cz/"} url={"https://www.frydekmistek.cz/"}>
              </Image>

              <Image style={{"maxWidth":147}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/5164cc34b0f8483c8f5d2add3a4dfdc4_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/5164cc34b0f8483c8f5d2add3a4dfdc4_s=660x_.png 660w"} alt={""} src={"https://cdn.swbpg.com/t/37955/5164cc34b0f8483c8f5d2add3a4dfdc4_s=660x_.png"} lightbox={false} use={"url"} href={"https://www.frydekmistek.cz/"} url={"https://www.frydekmistek.cz/"}>
              </Image>

              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\"><br><br><br><br><br><br>Co je u nás k vidění?</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--center fs--14 ls--008 lh--14" style={{"maxWidth":471}} content={"<span style=\"font-weight: bold;\">Stovky exponátů !<br><br>Meteority včetně velmi vzácných, které pocházejí z Měsíce, nebo planety Mars.\n<br><br>Tektity, které vznikají po nárazu obřích asteroidů na zemský povrch (např. české Vltavíny).<br><br>&nbsp;Pseudometeority - ukázky pozemských hornin, které si lidé nejčastěji s meteority pletou.\n<br><br>&nbsp;Impaktní horniny - pozemské kameny z míst planety Země, kde kdysi dávno dopadly obrovské asteroidy.<br><br>Repliky historicky významných meteoritů.&nbsp;<br></span><span style=\"color: var(--color-dominant); font-weight: bold;\"><br></span><span style=\"font-weight: bold;\">Ukázky padělků meteoritů.<br></span><span style=\"color: var(--color-dominant); font-weight: bold;\"><br>NĚKTERÉ EXPONÁTY SI U NÁS MŮŽEŠ DOKONCE POTĚŽKAT !<br></span>"}>
              </Text>

              <Text className="text-box text-box--center fs--14 w--600 ls--008" style={{"maxWidth":621}} content={"<span style=\"color: var(--color-dominant);\"><br><br></span>Mnoho informací o meteoritech, jejich původu, složení a jak se k nám dostávají.<br>Jak meteorit poznat a jak jej najít.<br>Literatura k tématu, možnost i zapůjčit.<br><br>Příjemná komorní atmosféra.<br><br>"}>
              </Text>

              <Image style={{"maxWidth":703}} alt={""} src={"https://cdn.swbpg.com/t/37955/18dcb6de03194183a2f68b0399060439_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/18dcb6de03194183a2f68b0399060439_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/18dcb6de03194183a2f68b0399060439_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/18dcb6de03194183a2f68b0399060439_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/18dcb6de03194183a2f68b0399060439_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/18dcb6de03194183a2f68b0399060439_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Divider >
              </Divider>

              <Text className="text-box text-box--center fs--30" content={"<span style=\"color: var(--color-dominant); font-weight: bold;\">VSTUP JE ZDARMA</span>"}>
              </Text>

              <Text className="text-box text-box--center fs--16 w--900" style={{"maxWidth":594}} content={"Frýdek-Místek\n<br>&nbsp;Náměstí Svobody 33 (Místek)<br><br><span style=\"color: rgb(13, 243, 40);\">otevírací doba do 23.12.2024:&nbsp;<br></span>Pondělí - Pátek<br>9 - 16 hod.<br><br>+ Mimořádné víkendové a sváteční termíny zveřejňujeme ZDE:<br><span style=\"color: rgb(10, 243, 38);\">SOBOTA 28.9. OTEVŘENO 10-15 HOD.</span><br>"}>
              </Text>

              <Divider >
              </Divider>

              <Image style={{"maxWidth":635}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=860x_.jpg"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(76, 234, 22);\">PŘIPRAVUJEME NOVÉ, VĚTŠÍ <br>A JEŠTĚ UNIKÁTNĚJŠÍ MUZEUM !<br>PLÁNOVANÉ OTEVŘENÍ - JARO 2025<br></span><span style=\"font-weight: bold; color: rgb(38, 221, 222);\"><a href=\"https://www.meteority.com/nove-muzeum/\" target=\"_blank\" style=\"\">Přečti si první informace ZDE</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Divider >
              </Divider>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":512}} content={"<span style=\"font-weight: bold;\">Vstup je přímo z náměstí, hledej nápis na fasádě, <br>nebo ceduli u vchodu z podloubí :)\n<br><br>&nbsp;Na náměstí je pěší zóna, parkoviště je např. na ulici 8. Pěšího pluku.\n<br><br><span style=\"text-decoration-line: underline;\">&nbsp;Nejbližší zastávky MHD:\n</span><br>&nbsp;Místek, Ostravská\n<br>&nbsp;Místek, Poliklinika\n<br>&nbsp;Místek, Anenská</span><br>"}>
              </Text>

              <Image style={{"maxWidth":801}} alt={""} src={"https://cdn.swbpg.com/t/37955/00f905bc5db94b95b041ee2c77dcf55c_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/00f905bc5db94b95b041ee2c77dcf55c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/00f905bc5db94b95b041ee2c77dcf55c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/00f905bc5db94b95b041ee2c77dcf55c_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold;\"><br>Speciální poděkování za podporu:</span><br>"}>
              </Text>

              <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/50a8766748ad4e72bda3aa7ddce2d3dc_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/37955/50a8766748ad4e72bda3aa7ddce2d3dc_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.avetour.cz/"} url={"https://www.avetour.cz/"}>
              </Image>

              <Text className="text-box fs--26 w--900" content={"<span style=\"color: var(--color-custom-1--15);\"><br>Kamenná prodejna</span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":501}} content={"<span style=\"font-weight: bold;\">V muzeu možnost zakoupit různé zboží.\n\nMeteority a tektity nejen v surovém stavu.\n<br>&nbsp;Řezy, plátky, potřeby pro sběratele.\n\nŠperky a různě zpracované kameny.\n<br>&nbsp;Poradenství, úprava meteoritů, čištění, analýzy a mnoho dalšího.<br><br><br></span>"}>
              </Text>

              <Text className="text-box fs--14" style={{"maxWidth":497}} content={"<span style=\"font-weight: bold;\">Ověřené meteority z celého světa.<br>Unikátní kameny, <br>nebo originální šperky najdeš v obchodě:<br></span>"}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://www.meteority.com/action/Affiliate/setAccess/?partnerId=4&amp;bannerId=4\"><img src=\"https://www.meteority.com/user/affiliate/yellow_and_green_multicolored_earth_day_web_banner.jpg\" alt=\"Originál z Muzea meteoritů (600x200px)\"></a>"}>
              </Text>

              <Text className="text-box fs--14" style={{"maxWidth":600}} content={"<span style=\"font-weight: bold;\"><br>Jako třetí prodejce v Česku a na Slovensku je tento obchod členem <br>prestižní Světové asociace sběratelů meteoritů IMCA<br>a ty tak máš jistotu pravosti a autenticity.<br></span>"}>
              </Text>

              <Text className="text-box fs--16" content={"<a href=\"https://www.meteority.com/action/Affiliate/setAccess/?partnerId=4&amp;linkId=1\" title=\"Unikátní dárek z Muzea meteoritů\" style=\"font-weight: bold;\">Unikátní dárek z Muzea meteoritů</a>"}>
              </Text>

              <Text className="text-box" content={"Zadejte text"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 pb--60 pt--60" style={{"marginBottom":147,"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"6wt5m8o0t9h"} animS={"3"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/00e9efa8f015434cbe4c6c18471fc4ee_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/00e9efa8f015434cbe4c6c18471fc4ee_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/00e9efa8f015434cbe4c6c18471fc4ee_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/00e9efa8f015434cbe4c6c18471fc4ee_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/662e79dafb974af2ba49876c98698f84_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/662e79dafb974af2ba49876c98698f84_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/662e79dafb974af2ba49876c98698f84_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/662e79dafb974af2ba49876c98698f84_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/662e79dafb974af2ba49876c98698f84_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/101a8d13bc1344d39c40e12cfd713085_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/101a8d13bc1344d39c40e12cfd713085_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/101a8d13bc1344d39c40e12cfd713085_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/101a8d13bc1344d39c40e12cfd713085_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/e364d8d94c004354a059c7458ee745b0_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/e364d8d94c004354a059c7458ee745b0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/e364d8d94c004354a059c7458ee745b0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/e364d8d94c004354a059c7458ee745b0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/e364d8d94c004354a059c7458ee745b0_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/1dfaabfc7e024d3da88833c2c632a429_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/1dfaabfc7e024d3da88833c2c632a429_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/1dfaabfc7e024d3da88833c2c632a429_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/1dfaabfc7e024d3da88833c2c632a429_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/1dfaabfc7e024d3da88833c2c632a429_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/3bd2e7f1110e4ba4ac675b537f6f35c6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/3bd2e7f1110e4ba4ac675b537f6f35c6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/3bd2e7f1110e4ba4ac675b537f6f35c6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/3bd2e7f1110e4ba4ac675b537f6f35c6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/3bd2e7f1110e4ba4ac675b537f6f35c6_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 --center" style={{"paddingTop":5,"marginBottom":34,"paddingBottom":4}} anim={"3"} name={"c04mrgkcf47"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Spolupracujeme:</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wso82rfspzo"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":245}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/1ae8760f533240cbb0a3792e6f213505_s=860x_.jpg"} url={"https://hvezdar.cz/"} use={"url"} href={"https://hvezdar.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/1ae8760f533240cbb0a3792e6f213505_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/1ae8760f533240cbb0a3792e6f213505_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/1ae8760f533240cbb0a3792e6f213505_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/1ae8760f533240cbb0a3792e6f213505_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":245}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/4f666fc581e94273af193d82fc035ed1_s=660x_.png"} url={"https://www.astro-web.sk/"} use={"url"} href={"https://www.astro-web.sk/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/4f666fc581e94273af193d82fc035ed1_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/4f666fc581e94273af193d82fc035ed1_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/4f666fc581e94273af193d82fc035ed1_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":245}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=660x_.png"} url={"https://www.frydekmistek.cz/"} use={"url"} href={"https://www.frydekmistek.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":245}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/3b5a0232364d44699ae706cd631c7a07_s=350x_.jpg"} url={"https://gardennature.cz/"} use={"url"} href={"https://gardennature.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/3b5a0232364d44699ae706cd631c7a07_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":159}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/37955/c65fefc0687e408f8a729a8b7ce3c394.jpeg"} url={"https://ticfm.cz/"} use={"url"} href={"https://ticfm.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":159}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/37955/15e7ccd20c5140f094e95f5425d128bf.jpeg"} url={"http://hvezdaren.org/"} use={"url"} href={"http://hvezdaren.org/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/f566aced4f3b40c1885219565e20d78f_s=350x_.png"} url={"https://www.nm.cz/"} use={"url"} href={"https://www.nm.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/f566aced4f3b40c1885219565e20d78f_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/8d6520f4415d495fbc75e73052be131a_s=660x_.png"} url={"https://www.petrhoralek.com/"} use={"url"} href={"https://www.petrhoralek.com/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/8d6520f4415d495fbc75e73052be131a_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/8d6520f4415d495fbc75e73052be131a_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/8d6520f4415d495fbc75e73052be131a_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/23332137ea854a288ce9a97dfff43939_s=350x_.jpeg"} url={"https://www.eminerals.cz/"} use={"url"} href={"https://www.eminerals.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/23332137ea854a288ce9a97dfff43939_s=350x_.jpeg 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":159}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/37955/7f5801fc950c4aa0b79d7f587f8fe22a.jpg"} url={"http://www.boto.cz/"} use={"url"} href={"http://www.boto.cz/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/d1267b58778b40efab54493579b6412e_s=860x_.png"} url={"https://www.meteority.com/"} use={"url"} href={"https://www.meteority.com/"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/d1267b58778b40efab54493579b6412e_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/d1267b58778b40efab54493579b6412e_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/d1267b58778b40efab54493579b6412e_s=860x_.png 860w, https://cdn.swbpg.com/t/37955/d1267b58778b40efab54493579b6412e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/37955/d1267b58778b40efab54493579b6412e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 --center" style={{"paddingTop":4,"paddingBottom":0}} anim={"3"} name={"nrju5rug31"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.&nbsp; &nbsp;© 2024 Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}